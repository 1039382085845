<template>
  <vp-base-layout
    page-title="수거내역"
    page-default-back-link="/">
    <vp-template-list
      :datas="datas"
      :total="pageInfo.total"
      detail-icon="false">
      <template #top>
        <ion-list lines="full">
          <ion-item>
            <ion-label>입금대상목록 (총 {{ $filters.countTxt(pageInfo.total) }})</ion-label>
            <ion-icon
              :icon="funnelOutline"
              @click="presentActionSheet"
              slot="end" />
          </ion-item>
        </ion-list>
      </template>
      <template #contents="{ item }">
        <ion-label>
          <h2>{{ item.title }}</h2>
          <p>{{ item.description }}</p>
        </ion-label>
      </template>
    </vp-template-list>
    <vp-button
      expand="full"
      :types="[{'fixed': fixed}]"
      @click="onClickPickupDetailDownload()">
      수거내역 다운로드
    </vp-button>
  </vp-base-layout>
</template>

<script setup>
//---------------------------------------------------------------------------------------------------------------------
// import
//---------------------------------------------------------------------------------------------------------------------
import { IonList, IonItem, IonLabel, IonIcon } from '@ionic/vue'
import { funnelOutline } from 'ionicons/icons'
import { ref, inject, onMounted } from 'vue'
import VpTemplateList from '@/components/templates/list/VpTemplateList.vue'
import { getOrders } from '@/api/purchase/purchase'
import { useIonRouter, actionSheetController } from '@ionic/vue'
//---------------------------------------------------------------------------------------------------------------------
// 변수 선언 && 함수 선언
//---------------------------------------------------------------------------------------------------------------------
const ionRouter = useIonRouter()
const fixed = ref(true)
const dayjs = inject('$dayjs')
const datas = ref([])

const pageInfo = ref({
  total: 0,
  pageNum: 1,
  pageSize: 99999
})

const getOrderList = async (query) => {
  const orders = await getOrders(query)
  orders.data.results.forEach((obj) => {
    let orderStateTxt = undefined
    switch (Number(obj.order_state_code)) {
      case 2:
       orderStateTxt = '수거'  
       obj.description = dayjs(obj.take_date).format('YYYY-MM-DD A hh:mm:ss')
       break
      case 3:
        orderStateTxt = '완료(입고)'  
        obj.description = dayjs(obj.take_date).format('YYYY-MM-DD A hh:mm:ss')
        break
      default:
        orderStateTxt = '접수'  
        obj.description = dayjs(obj.order_date).format('YYYY-MM-DD A hh:mm:ss')
    }

    let title = []
    if (obj.order_nm) {
      title.push(obj.order_nm)
    }
    if (obj.order_tel_no) {
      title.push(obj.order_tel_no)
    }
    if (orderStateTxt) {
      title.push(orderStateTxt)
    }
    
    if (Number(obj.order_state_code) === 2) {
      title.push(obj.grade1)
    } else if (Number(obj.order_state_code) === 3) {
      title.push(obj.grade2)
    }
    if (obj.grade1_price) {
      title.push(Number(obj.grade1_price).toLocaleString())
    }
    obj.title = title.join(' / ')
  })
  datas.value = orders.data.results
  pageInfo.value.total = orders.data.count
}

const onClickPickupDetailDownload = () => {
  ionRouter.push({name: 'collectionDetail'})
}

////// 필터부분 추가 시작 - 임시 ///////
//const result = ref('')
const presentActionSheet = async () => {
  const actionSheet = await actionSheetController.create({
    header: '선택한 접수상태 목록만 보여집니다.',
    //subHeader: '선택한 항목만 보여집니다',
    buttons: [
      
      {
        text: '접수',
        data: {
          action: '1'
        }
      },
      {
        text: '수거',
        data: {
          action: '2'
        }
      },
      {
        text: '완료(입고)',
        data: {
          action: '3'
        }
      },
      {
        text: 'Cancel',
        role: 'cancel',
        data: {
          action: 'cancel'
        }
      }
    ]
  })

  await actionSheet.present()
  const res = await actionSheet.onDidDismiss()

  if(res.role != 'cancel') {
    const param = pageInfo
    param.value.order_state_code = res.data.action
    await getOrderList(param.value)
  }

//  result.value = JSON.stringify(res, null, 2)
  
}

////// 필터부분 추가 끝 - 임시 ///////

//---------------------------------------------------------------------------------------------------------------------
// life Cycle
//---------------------------------------------------------------------------------------------------------------------
onMounted(async () => {
  await getOrderList(pageInfo.value)
})
</script>
